import { detect } from 'detect-browser';

/**
 * Gets name of detected browser.
 * @returns {string}
 */
const getBrowserName = () => {
  const browser = detect();
  return browser?.name ?? 'Not detected browser name';
};

/**
 * Checks if current browser is capable of loading Pdf view.
 * Note that for now it is always true but if we want to drop PDF support for browsers that do not understand es6.
 * @returns {boolean}
 */
const canLoadPdf = () => true;

export {
  canLoadPdf,
  getBrowserName,
};
