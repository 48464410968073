/* eslint-disable no-param-reassign */
import { isPending, isActive, isInactive, compareAscendingInsensitive } from '../../helpers/users';
import request from 'helpers/diligen-xhr';
import axios from 'helpers/diligen-xhr-axios';
import { waitAtLeast } from 'shared/utils';

export default {
  state: {
    users: [],
  },

  getters: {
    pendingUsers: state => state.users.filter(isPending).sort(compareAscendingInsensitive),
    activeUsers: state => state.users.filter(isActive).sort(compareAscendingInsensitive),
    inactiveUsers: state => state.users.filter(isInactive).sort(compareAscendingInsensitive),
    accountOwners: state => state.users
      .filter(user => isActive(user) && user.account_type === 'owner')
      .sort(compareAscendingInsensitive),
    getUserById({ users }) {
      return id => users.find(user => user.id === id);
    },
  },

  mutations: {
    setUsers(state, users) {
      state.users = users;
    },

    addUsers(state, users) {
      state.users = state.users.concat(users);
    },

    updateUser(state, updatingUser) {
      if (!updatingUser?.id) {
        throw new Error(`No ID set in updating user: ${JSON.stringify(updatingUser)}`);
      }

      // Replace the state's user with matching ID with the updating user
      state.users = state.users.map(user => (user.id === updatingUser.id ? updatingUser : user));
    },

    removeUser(state, removingUser) {
      state.users = state.users.filter(user => user.id !== removingUser.id);
    },
  },

  actions: {
    /**
     * Load and set all users in store.
     *
     * @param {import('vuex').ActionContext} commit - Function to commit changes via mutators.
     */
    fetchUsers: async ({ commit }) => {
      const response = await axios.get('/api/users');
      commit('setUsers', response.data.data);
    },

    /**
     * Update a user in the backend and the store.
     *
     * @param {import('vuex').ActionContext} commit - Function to commit changes via mutators.
     * @param {{ user: object, values: object, genericError: string }} payload - The action payload.
     *
     * @return {Promise<{ success: boolean, errors?: [{ title: string }]}>}
     */
    updateUser: async ({ commit }, { user, values, genericError }) => {
      let response;
      try {
        response = (await waitAtLeast(1000, request.post(`/api/users/${user.id}`).send(values))).body;
        commit('updateUser', response.data.user);
      }
      catch (error) {
        return {
          success: false,
          errors: error?.response?.body?.errors ?? [{ title: genericError }],
        };
      }

      return { success: true };
    },

    /**
     * Create pending users and send them invitations.
     *
     * @param {import('vuex').ActionContext} commit - Function to commit changes via mutators.
     * @param {object} invitees - The users to invite.
     *
     * @return {Promise<{success: boolean, errors?: [{id: number, title: string}]}>}
     */
    sendInvitation: async ({ commit }, invitees) => {
      try {
        const response = (await waitAtLeast(1000, request.post('/api/users').send(invitees))).body;
        commit('addUsers', response.data.users);

        return { success: true };
      }
      catch (error) {
        return {
          success: false,
          errors: error?.response?.body?.errors ?? [{ id: 0, title: 'Could not send invitation.' }],
        };
      }
    },

    /**
     * Resend invitation to a pending user.
     *
     * @param {object} context - The context of this action.
     * @param {object} user - The pending user to send an invite for.
     *
     * @return {Promise<boolean>}
     */
    resendInvitation: async (context, user) => {
      try {
        await waitAtLeast(1000, request.post(`/api/users/${user.id}/resend`));
        return true;
      }
      catch (error) {
        return false;
      }
    },

    /**
     * Cancel an invite sent to a pending user, deleting them in the process.
     *
     * @param {import('vuex').ActionContext} commit - Function to commit changes via mutators.
     * @param {object} user - The user to cancel the invite for.
     *
     * @return {Promise<{success: boolean, message?: string}>}
     */
    cancelInvite: async ({ commit }, user) => {
      try {
        await waitAtLeast(1000, request.post(`/api/users/${user.id}/cancel`));
        commit('removeUser', user);

        return { success: true };
      }
      catch (error) {
        return {
          success: false,
          message: 'Could not cancel invitation.',
        };
      }
    },

    /**
     * Unlock a user that has been locked due to too many password reset failures.
     *
     * @param {import('vuex').ActionContext} commit - Function to commit changes via mutators.
     * @param {object} user - The user to cancel the invite for.
     *
     * @return {Promise<{success: boolean, message?: string}>}
     */
    unlockUser: async ({ commit }, user) => {
      try {
        await waitAtLeast(1000, request.post(`/api/users/${user.id}/unlock`));
        user.is_locked_out = false;
        commit('updateUser', user);

        return { success: true };
      }
      catch (error) {
        return {
          success: false,
          message: 'Could not unlock user.',
        };
      }
    },
  },
};
