const statuses = {
  CONVERTED_READY_FOR_PROCESSING: 'Done making PDF',
  DONE_OCR_PROCESSING: 'Done OCR',
  ERROR_IN_OCR: 'Unreadable PDF (OCR)',
  PROCESSED: 'Processed',
  QUEUED_FOR_CONVERSION_TO_PDF: 'Making PDF',
  QUEUED_FOR_ML_ANALYSIS: 'Analyzing document',
  QUEUED_FOR_OCR_DECISION: 'Checking if OCR needed',
  QUEUED_FOR_OCR_PROCESSING: 'Performing OCR',
  QUEUED_FOR_PROCESSING: 'Queued for processing',
  QUEUED_FOR_REPROCESSING: 'Reprocessing required',
  QUEUED_FOR_RULES: 'Queued for rules',
  QUEUED_FOR_TRANSLATE: 'Detecting languages',
  READY_FOR_S3_UPLOAD: 'Ready for document storage upload',
  UNCONVERTABLE_FILE: 'Unreadable source document',
  UNREADABLE_PDF: 'Unreadable PDF document',
  UNSUPPORTED_FILE_TYPE: 'Unsupported file type',
};

// Export each status individually
export const {
  CONVERTED_READY_FOR_PROCESSING,
  DONE_OCR_PROCESSING,
  ERROR_IN_OCR,
  PROCESSED,
  QUEUED_FOR_CONVERSION_TO_PDF,
  QUEUED_FOR_ML_ANALYSIS,
  QUEUED_FOR_OCR_DECISION,
  QUEUED_FOR_OCR_PROCESSING,
  QUEUED_FOR_PROCESSING,
  QUEUED_FOR_REPROCESSING,
  QUEUED_FOR_RULES,
  QUEUED_FOR_TRANSLATE,
  READY_FOR_S3_UPLOAD,
  UNCONVERTABLE_FILE,
  UNREADABLE_PDF,
  UNSUPPORTED_FILE_TYPE,
} = Object.keys(statuses).reduce((acc, key) => ({ ...acc, [key]: key }), {});

/**
 * Gets human-readable text information corresponding to a document status.
 * @param {string} value - one of the enum values like 'QUEUED_FOR_PROCESSING'.
 * @returns {string|undefined} - human readable information or undefined if status is not found.
 */
export const getInfo = value => statuses[value];

// Export the entire object as default
export default {
  ...Object.keys(statuses).reduce((acc, key) => ({ ...acc, [key]: key }), {}),
  getInfo,
};
