import {
  CONVERTED_READY_FOR_PROCESSING,
  DONE_OCR_PROCESSING,
  QUEUED_FOR_CONVERSION_TO_PDF,
  QUEUED_FOR_ML_ANALYSIS,
  QUEUED_FOR_OCR_DECISION,
  QUEUED_FOR_OCR_PROCESSING,
  QUEUED_FOR_PROCESSING,
  QUEUED_FOR_RULES,
  QUEUED_FOR_TRANSLATE,
} from 'shared/constants/file-processing-statuses';

const UNPROCESSED_STATES = [
  CONVERTED_READY_FOR_PROCESSING,
  DONE_OCR_PROCESSING,
  QUEUED_FOR_CONVERSION_TO_PDF,
  QUEUED_FOR_ML_ANALYSIS,
  QUEUED_FOR_OCR_DECISION,
  QUEUED_FOR_PROCESSING,
  QUEUED_FOR_RULES,
  QUEUED_FOR_TRANSLATE,
];

/**
 * Returns true if document has been processed.
 * @param {object} document
 * @returns boolean
 */
export function isProcessed(document) {
  return !UNPROCESSED_STATES.includes(document.file_status) && !isOcrProcessing(document);
}

export function isOcrProcessing(document) {
  return document.file_status === QUEUED_FOR_OCR_PROCESSING;
}
