export const FILE_DELETED = 'File Deleted';
export const FILE_STATUS_UPDATED = 'File Status Updated';
export const FILE_UPLOADED = 'File Uploaded';
export const FILE_UPDATED = 'File Updated';
export const LAYOUTS_DELETED = 'Layouts Deleted';
export const PROJECT_FIELD_CREATED = 'Project Field Created';
export const PROJECT_FIELD_DELETED = 'Project Field Deleted';
export const PROJECT_FIELD_UPDATE = 'Project Field Updated';
export const REVIEW_UPDATE = 'Review Status Updated';
export const REVIEWERS_UPDATED = 'Reviewers Updated';
export const TAGS_MODIFIED = 'Tags Modified';
export const TEAM_UPDATED = 'Team Updated';
