import Vue from 'vue';
import request from 'helpers/diligen-xhr';
import { getField } from 'helpers/project-field-and-clause';

export default {
  state: {
    userProjectLayout: [],
    userDocumentLayout: [],
    documentPresentFields: false,
    layoutTemplate: null,
  },
  getters: {
    hasUserLayout: state => state.userProjectLayout.length
      || state.userDocumentLayout.length
      || state.documentPresentFields,
    /**
     * Gets the additional layouts for sdv and maps the project field names to ids.
     *
     * @param {object} state - The vuex state to read from.
     * @param {Array<object>} state.layoutTemplate - The project template.
     * @param {object} getters - The module getters.
     * @param {object} rootState - The global state tree.
     *
     * @return {Record<string, Array<string|number>>}
     */
    sdvTemplateLayouts({ layoutTemplate }, getters, rootState) {
      const { clauses, projectFields } = rootState.sdv;
      const layouts = layoutTemplate?.document_page;
      const mlKeys = clauses.map(clause => clause.ml_key);
      let mappedLayouts = {};
      if (layouts && mlKeys.length) {
        Object.entries(layouts).forEach(([name, layout]) => {
          const mappedLayout = layout.reduce((arr, sdvField) => {
            if (mlKeys.includes(sdvField)) {
              return arr.concat(sdvField);
            }
            if (projectFields.length) {
              const matchedField = projectFields.find(field => sdvField === field.project_field_key);
              if (matchedField) {
                return arr.concat(matchedField.id);
              }
            }
            return arr;
          }, []);

          mappedLayouts[name] = mappedLayout;
        });
      }
      return mappedLayouts;
    },
    pvLayout({ layoutTemplate }, getters, rootState) {
      const layout = layoutTemplate?.project_page;
      return layout?.map(entry =>
        rootState.project.project_fields.find(field => field.project_field_key === entry)?.name
        || rootState.project.clauses.find(clause => clause.ml_key === entry)?.name
        || entry);
    },
    sdvFieldsPreferences({ userDocumentLayout, documentPresentFields }, { sdvLayout }) {
      if (!userDocumentLayout.length) {
        // If there is no saved document layout for this project check if all present fields were selected.
        if (documentPresentFields) {
          return null;
        }
        // Use the template layout if it exists, or null (default) if not.
        return sdvLayout.length ? sdvLayout : null;
      }
      return userDocumentLayout;
    },
    /**
     * Checks if user has set preferences for which fields to display for this document
     * @param  {object} state - vuex state to read
     * @param  {object} getters - The module getters.
     * @param  {string[]|null} getters.sdvFieldsPreferences - preferences set by user
     * @return {boolean} tells us if user has preferences set
     */
    hasPreferencesSet(state, { sdvFieldsPreferences }) {
      return !!sdvFieldsPreferences;
    },
    /**
     * Gets valid user preference for the fields that should be visible for documents in this project.
     * @param {object} state - The module state.
     * @param {Array<object>} state.projectFields - project fields from state
     * @param {Array<object>} state.clauses - The clauses from state.
     * @param {object} getters - The module getters.
     * @param {Array<string|number>|null} getters.sdvFieldsPreferences - preferences set by user
     * @return {Array<string|number>|null} Array of identifiers (mlKey for clause, id for project field) or null
     */
    validProjectPreferences(state, { sdvFieldsPreferences }, rootState) {
      // Filter out preferences which do not have corresponding fields - can happen if a field set as a preference
      // was at later point removed from the system (for example a clause was deleted)
      const { clauses, projectFields } = rootState.sdv;
      return sdvFieldsPreferences
        ? sdvFieldsPreferences.filter(preference => !!getField(preference, clauses, projectFields))
        : null;
    },
    /**
     * Get the selected layout name computed from the current field preferences.
     */
    selectedSdvLayoutName({ layoutTemplate }, { sdvTemplateLayouts, sdvFieldsPreferences, sdvLayout }) {
      const compareLayoutToPreferences = layout =>
        (layout.length === sdvFieldsPreferences.length)
        && layout.every(field => sdvFieldsPreferences.includes(field));

      const layout = Object
        .keys(sdvTemplateLayouts)
        .find(layoutName => compareLayoutToPreferences(sdvTemplateLayouts[layoutName]));
      const templateName = layoutTemplate?.name;
      const defaultLayout = compareLayoutToPreferences(sdvLayout) ? templateName : null;

      return layout || defaultLayout;
    },
    /**
     * Gets the sdv layout template (default) and maps the project field names to ids.
     */
    sdvLayout({ layoutTemplate }, getters, rootState) {
      const layouts = layoutTemplate?.document_page;
      let firstLayout;
      if (layouts) {
        const [firstLayoutKey] = Object.keys(layouts);
        if (firstLayoutKey) {
          firstLayout = layouts[firstLayoutKey];
        }
      }
      const sdvLayout = layoutTemplate?.document_page?.default ?? firstLayout;
      const { clauses, projectFields } = rootState.sdv;
      const mlKeys = clauses.map(clause => clause.ml_key);
      let mappedLayout = [];
      if (sdvLayout && mlKeys.length) {
        mappedLayout = sdvLayout.reduce((arr, sdvField) => {
          if (mlKeys.includes(sdvField)) {
            return arr.concat(sdvField);
          }
          if (projectFields.length) {
            const matchedField = projectFields.find(field => sdvField === field.project_field_key);
            if (matchedField) {
              return arr.concat(matchedField.id);
            }
          }
          return arr;
        }, []);
      }
      return mappedLayout;
    },
    reportLayouts({ layoutTemplate }) {
      return layoutTemplate?.reports;
    },
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    setUserLayout(state, { project_layout, document_layout, use_present_fields = false }) {
      state.documentPresentFields = use_present_fields;
      state.userProjectLayout = project_layout ?? [];
      state.userDocumentLayout = use_present_fields ? [] : (document_layout ?? []);
    },
    setDiligenLayoutTemplate(state, layoutTemplate) {
      state.layoutTemplate = layoutTemplate;
    },
  },
  /* eslint-enable no-param-reassign */
  actions: {
    async fetchUserLayout({ commit, rootState: { route } }) {
      const id = route.params.projectId;
      const { body: { data } } = await request.get(`/api/projects/${id}/layout`);
      commit('setUserLayout', data);
    },
    async fetchProjectLayoutTemplate({ commit, rootState }, project_id) {
      // Fallback to the param as project ID is not present in the overview page route.
      const projectId = rootState.route.params.projectId || project_id;
      const { body: { data } } = await request.get(`/api/projects/${projectId}/template`);
      commit('setDiligenLayoutTemplate', data.template ? { projectId, ...data.template } : null);
    },
    async saveUserLayout({ commit, dispatch, getters, rootState: { route } }, layoutData) {
      try {
        const method = getters.hasUserLayout ? 'patch' : 'post';
        const id = route.params.projectId;
        await request[method](`/api/projects/${id}/layout`).send(layoutData);
        commit('setUserLayout', layoutData);
      }
      catch (error) {
        // It's possible that the layout could have been removed - retry after clearing the state.
        if (error.status === 404) {
          commit('setUserLayout', {});
          return dispatch('saveUserLayout', layoutData);
        }
        if (!error.handledByDiligenXhr) {
          Vue.diligenToast.showError('The layout could not be updated. Please try again.');
          throw error;
        }
      }
    },
  },
};
