import { DONE_OCR_PROCESSING } from 'shared/constants/file-processing-statuses';
import { isProcessed } from 'shared/processed-file';

/**
 * Vuex action that sets up listeners for sdv socket and sets socket to state with a mutation
 * @param  {object} state - sdv vuex state
 * @param  {object} getters - sdv vuex getters
 * @param  {Function} commit - function that commits to sdv module
 * @param  {Socket} socket - socket from socket.io
 */
export default ({ state, getters, commit, dispatch }, socket) => {
  socket.on('clauseCreated', ({ clause }) => commit('addClause', clause));

  socket.on('layoutsDeleted', () => commit('project/layout/setUserLayout', {}));

  socket.on('statusUpdate', async data => {
    if (data.fileId === getters.fileId) {
      if (isProcessed({ file_status: data.status })) {
        await dispatch('fetchRawDocumentData');
        await dispatch('fetchMappedData');
      }
      else {
        commit('setDocumentValue', { key: 'file_status', value: data.status });
        if (data.status === DONE_OCR_PROCESSING) {
          commit('setDocumentValue', { key: 'is_ocr', value: true });
        }
      }
    }
  });

  socket.on('updateFileTags', ({ fileIds, editBy, ...restData }) => {
    if (fileIds.includes(getters.fileId) && editBy !== socket.id) {
      commit('updateFileTags', { fileId: getters.fileId, ...restData });
    }
  });

  socket.on('deleteTag', id => commit('deleteProjectTag', id));

  socket.on('PROJECT_FIELD_ADDED', field => commit('addProjectField', field));

  socket.on('UPDATE_CLAUSE', update => {
    if (parseInt(update.fileId, 10) === getters.fileId && update.editBy !== socket.id) {
      commit('UPDATE_CLAUSE', { key: update.key, mlValue: update.value });
      commit('END_EDIT_FIELD', update.key);
    }
  });

  socket.on('UPDATE_FIELD', update => {
    if (parseInt(update.file_id, 10) === getters.fileId) {
      commit('updateProjectField', update);
      commit('END_EDIT_FIELD', update.project_field_id);
    }
  });

  socket.on('BLOCK_EDIT_CLAUSE', data => {
    if (data.editBy !== socket.id && getters.fileId === parseInt(data.fileId, 10)) {
      commit('BLOCK_EDIT_CLAUSE', data.key);
    }
  });

  socket.on('CANCEL_EDIT_FIELD', data => {
    if (getters.fileId === parseInt(data.fileId, 10)) {
      commit('END_EDIT_FIELD', data.key);
    }
  });

  socket.on('BLOCK_EDIT_FIELD', data => {
    if (data.editBy !== socket.id && getters.fileId === parseInt(data.fileId, 10)) {
      commit('BLOCK_EDIT_FIELD', data.key);
    }
  });

  socket.on('UPDATE_OVERVIEW', ({ fileId, payload, updatedClauses }) => {
    if (getters.fileId === parseInt(fileId, 10)) {
      commit('UPDATE_OVERVIEW', { payload, updatedClauses });
    }
  });

  commit('setSdvSocket', socket);
};
