export const SUPERADMIN_ONLY = 'superadmin_only';
export const MODIFY_ACCOUNT_INTEGRATION = 'modify_account_integration';
export const LIST_USERS = 'list_users';
export const INVITE_USER = 'invite_user';
export const MODIFY_USER = 'modify_user';
export const MODIFY_SUBSCRIPTION = 'modify_subscription';
export const CREATE_PROJECT = 'create_project';
export const VIEW_PROJECT = 'view_project';
export const EDIT_PROJECT = 'edit_project';
export const DELETE_PROJECT = 'delete_project';
export const UPLOAD_DOCUMENT = 'upload_document';
export const REPROCESS_DOCUMENT = 'reprocess_document';
export const VIEW_DOCUMENT = 'view_document';
export const EDIT_DOCUMENT = 'edit_document';
export const DELETE_DOCUMENT = 'delete_document';
export const MODIFY_TAGS = 'modify_tags';
export const MODIFY_CLAUSES = 'modify_clauses';
export const MARK_REVIEWED = 'mark_reviewed';
export const MARK_OUT_OF_SCOPE = 'mark_out_of_scope';
export const MODEL_TRAINING = 'model_training';
export const EDIT_RULES = 'edit_rules';
export const MANAGE_TEMPLATES = 'manage_templates';
