function groupUnderIndex(index, arr) {
  return arr.reduce((obj, elem) => {
    const key = elem[index];
    const valuesSoFar = obj[key] || [];
    return {
      ...obj,
      [key]: [...valuesSoFar, elem],
    };
  }, {});
}

/**
 * Wait for a certain amount of time.
 *
 * @param {number} time - The amount of time to wait for.
 *
 * @return {Promise}
 */
const waitFor = time =>
  (new Promise(resolve => setTimeout(resolve, time)));

/**
 * Wait until an evaluation is true.
 * @param {Function} evaluation - The function you want to evaluate true.
 * @param {number} waitAtMost - The maximum number of milliseconds to wait for.
 * @param {interval} interval - The interval of milliseconds to "step by".
 *
 * @return {Promise}
 */
async function waitUntil(evaluation, { waitAtMost = 5000, interval = 500 } = {}) {
  let waitedFor = 0;
  while (waitedFor < waitAtMost) {
    /* eslint-disable no-await-in-loop */
    if (await evaluation()) {
      break;
    }

    await waitFor(interval);
    waitedFor += interval;

    /* eslint-enable */
  }
}

/**
 * Returns promise value no sooner then given minTime.
 *
 * Useful for things like showing a loader when the server request is being processed, invoking it
 * like waitAtLeast(2000, myPotentiallyQuickServerAction) will make sure that if the response is
 * super quick there is no bad UX connected with a flashing loader. Instead the loader will spin
 * for 2000ms and only then will the promise fullfil.
 *
 * @param {number} minTime - Minimum time in MS we'd like to wait before resolving the promise.
 * @param {Promise} promise - Promise to fulfil.
 *
 * @return {Promise} this is a promise that resolves no earlier then minTime.
 * It resolves to the value of passed in promise.
 */
async function waitAtLeast(minTime, promise) {
  const delay = waitFor(minTime);
  try {
    const [promiseVal] = await Promise.all([
      promise,
      delay,
    ]);
    return promiseVal;
  }
  catch (error) {
    await delay; // Still wait for the expected duration when an error occurs.
    throw error;
  }
}

/**
 * Get a random entry from a list.
 *
 * @param {array} list - The list to choose from.
 *
 * @return {mixed}
 */
const randomFromList = list => {
  if (list.length === 0) {
    return undefined;
  }
  const randomIndex = Math.floor(Math.random() * list.length);
  return list[randomIndex];
};

/**
 * Remove new lines from the provided text.
 *
 * @param {string} text - The string to remove newlines from.
 *
 * @return {string}
 */
const removeNewlines = text => text.replace(/[\n\r]/g, '');

/**
 * Normalize a value to an array.
 *
 * @param {mixed} value - Something we need to normalize to an array.
 *
 * @return {Array}
 */
function ensureArray(value) {
  if (!value) {
    return [];
  }

  return Array.isArray(value) ? value : [value];
}

/**
 * Moves value in array. Does not mutate array.
 *
 * @param {Array} arr - array in which we want to move element
 * @param {number} from - where do we want to move the element from
 * @param {number} to - where do we want to move the element to
 *
 * @return {Array} - new array with changed order.
 */
function arrayMove(arr, from, to) {
  const clone = [...arr];
  clone.splice(to, 0, clone.splice(from, 1)[0]);
  return clone;
}

/**
 * Gets new value for the order property of the moved element.
 * @param {object} options
 * @param {Array} options.originalArray - array where we move the element (before move).
 * @param {number} options.oldIndex - previous index of the moved element.
 * @param {number} options.newIndex - new index for the moved element.
 * @returns {number} - new order value that is bigger then the previous element and lower then the next.
 */
function getOrderForDroppedItem({ originalArray, newIndex, oldIndex }) {
  const movedForward = newIndex > oldIndex;
  const afterIndex = movedForward ? newIndex : newIndex - 1;
  const beforeIndex = afterIndex + 1;
  const afterOrder = originalArray[afterIndex]?.order ?? (originalArray[0]?.order ?? 0) - 1;
  const beforeOrder = originalArray[beforeIndex]?.order ?? (originalArray[originalArray.length - 1]?.order ?? 0) + 1;
  return (afterOrder + beforeOrder) / 2;
}

module.exports = {
  arrayMove,
  getOrderForDroppedItem,
  groupUnderIndex,
  waitAtLeast,
  waitFor,
  waitUntil,
  randomFromList,
  removeNewlines,
  ensureArray,
};
