import Toasted from 'vue-toasted';
import getErrorMessage from 'helpers/get-api-error-message';

const DEFAULT_DELAY = 8000;

let toastsRegistry = {};

/**
 * Inits registry for toasts.
 * Toasts are pushed/popped as they come and go.
 * It allows us to introduce isSingleton option that does show a toast if it's duplicate is already shown.
 */
function initRegistry() {
  toastsRegistry = {
    info: {},
    success: {},
    error: {},
  };
}

function createToastInstance(Vue) {
  /**
   * Shows a wrapped version of vue-toasted toast.
   * @param {'info'|'success'|'error'} type
   * @param {string} message - message to display.
   * @param {object} options
   * @param {boolean} options.isSingleton - if true it won't display a toast with a message that is already shown.
   * @param {object} ...options - rest of options proxied to the vue-toasted toast method.
   * @returns {object} instance of diligen toast, extended version of toast from vue-toasted.
   */
  const showToast = (type, message, options = { isSingleton: false }) => {
    const wrappedMessage = `<div>${message}</div>`;

    // If isSingleton return early with the toast with same message from the registry.
    const existingToast = toastsRegistry[type]?.[wrappedMessage]?.[0];
    if (options.isSingleton && existingToast) {
      return existingToast;
    }
    const toast = Vue.toasted[type](wrappedMessage, options);
    /**
     * Function that makes toast goAway (using vue-toasted functionality) and removes toast from our registry.
     * @param {number} delay
     */
    const goAway = delay => {
      toast.goAway(delay);
      // Removing from registry implemented with setTimeout.
      // I wanted to use a cleaner solution with onComplete option from vue-toasted but it seems to me like it has bugs.
      setTimeout(() => {
        toastsRegistry[type][wrappedMessage].pop();
      }, delay);
    };

    if (!options.sticky) {
      const delay = options.delay || DEFAULT_DELAY;
      goAway(delay);
    }
    const diligenToast = {
      ...toast,
      goAway, // our goAway that wraps vue toasted goAway and removes toast from our registry.
    };
    toastsRegistry[type][wrappedMessage] = toastsRegistry[type][wrappedMessage] || [];
    toastsRegistry[type][wrappedMessage].push(diligenToast);
    return diligenToast;
  };

  return {
    clear: () => {
      Vue.toasted.clear();
      // Cleans up registry after all toasts are cleared.
      initRegistry();
    },
    showInfo: (message, options) => showToast('info', message, options),
    showSuccess: (message, options) => showToast('success', message, options),
    showError: (message, options) => showToast('error', message, options),
    showWarning: (message, options) => showToast('info', message, { ...options, className: 'warn' }),
    /**
     * Parses error response from the server and feeds it into an error toast.
     * @param {object} error - error from the response.
     * @param {string} genericErrorMessage - a fallback message to show in case server did not provide any error text.
     * @param {object} options - diligenToast options.
     * @returns {object|undefined} - toast, if shown.
     * Note that this toast could've been handled by diligen xhr general error handler.
     * In that case we skip showing the error here.
     */
    showApiError: (error, genericErrorMessage, options) => {
      if (!error.handledByDiligenXhr) {
        return showToast('error', getErrorMessage(error, genericErrorMessage), options);
      }
    },
  };
}

const DiligenToastPlugin = {
  install: (Vue, options) => {
    initRegistry();
    const vueToastedOptions = {
      position: 'bottom-left',
      ...options,
    };
    Vue.use(Toasted, vueToastedOptions);
    /* eslint-disable-next-line */
    Vue.diligenToast = Vue.prototype.$diligenToast = createToastInstance(Vue);
  },
};

export default DiligenToastPlugin;
