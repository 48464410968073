const EVENTS = {
  ANALYSIS_COMPLETE: 'Analysis Complete',
  USER_EDITED_CLAUSE: 'User Edited Clause',
  USER_EDITED_FIELD: 'User Edited Project Field',
  REVIEWER_ASSIGNED: 'Reviewer Assigned',
  TAG_APPLIED: 'Tag Applied',
};

const EVENT_TYPES = Object.values(EVENTS);

export {
  EVENTS,
  EVENT_TYPES,
};
