/* eslint-disable no-param-reassign */

import Vue from 'vue';
import Vuex from 'vuex';
import authModule from './auth';
import billingModule from './modules/billing';
import planModule from './modules/plan';
import projectModule from './modules/project/project';
import projectsOverviewModule from './modules/projects-overview';
import reportModule from './modules/report';
import rulesModule from './modules/rules';
import sdvModule from './modules/sdv';
import subscriptionModule from './modules/subscription';
import trainingModule from './modules/training';
import usageModule from './modules/usage';
import usersModule from './modules/users';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pageError: null,
  },
  getters: {
    /**
     * Returns value of the query param redirect_to, used in /login?redirect_to=/projects/10
     * @return {string} relative path to the url to redirect to once authenticated
     */
    redirectURL(state) {
      let relativePath = state.route?.query?.redirect_to ?? '';
      // Cut out potential leading slash to avoid double slash as we compose the full url later.
      relativePath = relativePath.startsWith('/') ? relativePath.substring(1) : relativePath;
      // do not allow redirection to login, makes no sense to redirect to login once authenticated
      relativePath = relativePath === 'login' ? '' : relativePath;
      const absoluteUrl = `https://${window.location.hostname}/${relativePath}`;
      return absoluteUrl;
    },
  },
  mutations: {
    setPageError(state, error) {
      state.pageError = error;
    },
  },
  modules: {
    auth: {
      namespaced: true,
      ...authModule,
    },
    plan: {
      namespaced: true,
      ...planModule,
    },
    project: {
      namespaced: true,
      ...projectModule,
    },
    projectsOverview: {
      namespaced: true,
      ...projectsOverviewModule,
    },
    subscription: {
      namespaced: true,
      ...subscriptionModule,
    },
    users: {
      namespaced: true,
      ...usersModule,
    },
    training: {
      namespaced: true,
      ...trainingModule,
    },
    usage: {
      namespaced: true,
      ...usageModule,
    },
    report: {
      namespaced: true,
      ...reportModule,
    },
    billing: {
      namespaced: true,
      ...billingModule,
    },
    rules: {
      namespaced: true,
      ...rulesModule,
    },
    sdv: {
      namespaced: true,
      ...sdvModule,
    },
  },
});
