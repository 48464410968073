import { getProjectFieldData, isClauseField, isProjectField } from './document-field';
import { toHashString } from 'helpers/sdv-url-hash';
import { getNonDataColumn, isSpecialFieldSortable, NON_DATA_FIELD_NAMES } from 'shared/constants/document-fields';
import { getExecutionStatusLabel } from 'shared/constants/execution-statuses';
import { getInfo } from 'shared/constants/file-processing-statuses';
import { getAltDate, getDocumentType, getParagraphs, isDateEmpty } from 'shared/shared-clause-helper';
import { getDuplicatePaths, getFormattedDateField } from 'shared/shared-document-model';

const { FOLDER, NUMBER_OF_PAGES, LAST_REVIEW_TIME } = NON_DATA_FIELD_NAMES;

function isFieldSortable(documentField) {
  return isClauseField(documentField)
    || isProjectField(documentField)
    || isSpecialFieldSortable(documentField.name);
}

// eslint-disable-next-line max-lines-per-function
function decoratedDocumentFactory(document, dateFormat) {
  return {
    ...document,
    /**
     * checks if there is some value that comes from data column (a json object from ml)
     * @param {string} mlKey
     * @return {boolean}
     */
    countDataFields(mlKey) {
      return document?.ml_json?.data?.[mlKey]?.length ?? 0;
    },
    hasDataField(mlKey) {
      return this.countDataFields(mlKey) !== 0;
    },
    getDataFieldTexts(mlKey) {
      return getParagraphs({ mlJsonData: document?.ml_json?.data, mlKey });
    },
    get date() {
      return getAltDate({ mlJsonData: document?.ml_json?.data, dateFormat });
    },
    get documentType() {
      return getDocumentType({ mlJsonData: document?.ml_json?.data });
    },
    get executionLabel() {
      return getExecutionStatusLabel(document.execution_status);
    },
    get lastReviewTime() {
      return getFormattedDateField(document, dateFormat, LAST_REVIEW_TIME);
    },
    get statusToText() {
      return getInfo(document.file_status);
    },
    get numberOfPages() {
      return document[getNonDataColumn(NUMBER_OF_PAGES)];
    },
    get folderPath() {
      return document[getNonDataColumn(FOLDER)];
    },
    get isDateEmpty() {
      return isDateEmpty({ mlJsonData: document?.ml_json?.data });
    },
    get hasOcr() {
      return document.is_ocr;
    },
    get contractParties() {
      return this.getDataFieldTexts('contract_parties');
    },
    getProjectFieldData({ projectFields, projectFieldName }) {
      return getProjectFieldData({
        projectFields,
        fileId: document.file_id,
        projectFieldName,
      });
    },
    /**
     * Builds Location for SDV that you can pass to router-link as a :to prop.
     * @param {number} projectId - id of the project that holds the document.
     * @param {string} [mlKey] - clause you want to jump to after entering SDV.
     * @param {object} query - query specifying page, sort and filter you want to pass to SDV
     * so that you can come back to the "last" pv you've seen.
     * Also there is pageFileIds and documentsCount data in the query so that you can use SDV pagination.
     * @return {Location} - an object with SDV url info you can pass as a :to prop to router-link.
     */
    sdvLocationBuilder({ projectId, mlKey, query }) {
      const path = `/documents/${projectId}/${document.file_id}`;
      const hash = mlKey ? `#${toHashString(mlKey, 0)}` : '';
      return {
        path,
        hash,
        query,
      };
    },
    get duplicatePaths() {
      return getDuplicatePaths(document);
    },
  };
}

export {
  decoratedDocumentFactory,
  isFieldSortable,
};
