import { EXAMPLE_MIN_COUNT_BEFORE_GENERATION } from 'shared/constants/example-suggestions';

// number of examples needed to trigger the different bot properties when training a model
export const BOT_EXAMPLES_TEXT_THRESHOLD_ONE = EXAMPLE_MIN_COUNT_BEFORE_GENERATION;
export const BOT_EXAMPLES_TEXT_THRESHOLD_TWO = 180;
export const BOT_EXAMPLES_TEXT_THRESHOLD_THREE = 300;

// f1 score difference for displaying messaging relating to model inconsistencies
export const F1_DROP_THRESHOLD = 0.01;

// number of f1 scores to keep for rolling calculations
export const F1_HISTORY_LENGTH = 5;

// negative example ratio thresholds for displaying relevant messaging in the text bubble
export const RATIO_THRESHOLD_LOW = 2.5;
export const RATIO_THRESHOLD_HIGH = 3.5;

// minimum number of examples before example ratio messaging is shown
export const RATIO_MESSAGING_MIN_EXAMPLES = 30;
