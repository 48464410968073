export const EXECUTION_STATUSES = {
  'Fully Executed': 'fully_executed',
  'Partially Executed': 'partially_executed',
  'Not Executed': 'not_executed',
  'Draft Agreement': 'draft_agreement',
  'Not Applicable': 'not_applicable',
};

export const availableValues = Object.values(EXECUTION_STATUSES);

export function getExecutionStatusLabel(executionStatusValue) {
  const label = Object.keys(EXECUTION_STATUSES).find(key => EXECUTION_STATUSES[key] === executionStatusValue);
  return label || null;
}

export function getExecutionStatusValue(executionStatusLabel) {
  const value = EXECUTION_STATUSES[executionStatusLabel];
  return value || null;
}
