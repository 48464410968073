import request from 'helpers/diligen-xhr';
import axios from 'helpers/diligen-xhr-axios';
import getErrorMessage from 'helpers/get-api-error-message';
import jwtUtils from 'helpers/jwt-utils';
import { MANAGE_TEMPLATES } from 'shared/constants/permissions';
import { SYSTEM_ROLE_SUPERADMIN } from 'shared/constants/system-roles';
import { waitAtLeast } from 'shared/utils';

let loadingPromise = null;

export default {
  state: {
    loggedInUser: {
      userId: null,
      isSubscribed: false,
      permissions: [],
      integrations: [],
    },
    pending2FA: false,
    apiKeyContext: {
      description: null,
      created_at: null,
    },
  },

  getters: {
    isLoggedInLoaded({ loggedInUser }) {
      return !!loggedInUser.userId;
    },
    isSuperAdmin({ loggedInUser }) {
      return loggedInUser.account_type === SYSTEM_ROLE_SUPERADMIN;
    },
    isTemplateManager({ loggedInUser }) {
      return loggedInUser.permissions?.includes(MANAGE_TEMPLATES);
    },
  },

  /* eslint-disable no-param-reassign */
  mutations: {
    setLoggedInUser(state, user) {
      const {
        user_id: userId,
        date_format: dateFormat,
        is_subscribed: isSubscribed,
        is_subscription_active: isSubscriptionActive,
        ...restUser
      } = user;
      const formattedUser = { userId, dateFormat, isSubscribed, isSubscriptionActive, ...restUser };
      localStorage.setItem('loggedInUser', JSON.stringify(formattedUser));
      state.loggedInUser = formattedUser;
    },
    setPending2FA(state, status) {
      state.pending2FA = status;
    },
    setApiContext(state, { description = null, created_at = null }) {
      state.apiKeyContext = { description, created_at };
    },
  },

  /* eslint-enable */
  actions: {
    /**
     * Load the currently logged-in user.
     *
     * @param {object} context - The Vuex action context.
     * @param {function} context.commit - Function to commit changes via mutators.
     * @param {{ ignore401?: boolean }} ignore401 - Does not cause the 401 current user request
     *                                 to be handled by diligen-xhr error handler
     */
    async initLoggedInUser({ commit }, { ignore401 } = {}) {
      if (!jwtUtils.isValid()) {
        commit('setLoggedInUser', {});
        return jwtUtils.clear();
      }

      if (loadingPromise) {
        return loadingPromise;
      }

      loadingPromise = axios.get('/api/users/me', {
        validateStatus: status => (status >= 200 && status < 300) || (status === 401 && ignore401),
      });

      try {
        const response = await loadingPromise;
        if (response.status === 401) {
          throw new Error('Ignored 401');
        }
        const { api_details = {}, ...restData } = response.data.data;
        commit('setLoggedInUser', restData);
        commit('setApiContext', api_details);
      }
      catch (error) {
        commit('setLoggedInUser', {});
        jwtUtils.clear();
      }
    },

    /**
     * Update your own user.
     *
     * @param {{ commit: function }} commit - Function to commit changes via mutators.
     * @param {object} values - Values to update current user with.
     * @param {string} genericError - A generic error message to display if a more specific one isn't provided.
     */
    updateCurrentUser: async ({ commit }, { values, genericError }) => {
      let response;
      try {
        response = (await waitAtLeast(1000, request.patch('/api/users/current').send(values))).body;
        commit('setLoggedInUser', response);
      }
      catch (error) {
        throw error.response?.body?.errors ?? [{ title: genericError }];
      }
    },

    /**
     * Log in a user.
     *
     * @param {{ commit: function }} commit - Function to commit changes via mutators.
     * @param {object} data
     * @param {string} data.email - The email address of the user to log in.
     * @param {string} data.password - The password of the user to log in.
     * @param {string} data.redirectURL - A URL to redirect to after a successful login.
     * @param {string} data.response - A reCAPTCHA response to pass to the server.
     */
    async loginUser({ commit }, { email, password, redirectURL, response }) {
      try {
        const { data } = (await request.post('/api/login').send({ email, password, redirectURL, response })).body;
        if (data.pending_twofa) {
          commit('setPending2FA', data.pending_twofa);
        }
        else {
          jwtUtils.store(data.jwt);
          commit('setLoggedInUser', data.me);

          window.location.href = data.redirect_url;
        }
      }
      catch (error) {
        commit('setLoggedInUser', {});
        jwtUtils.clear();
        const errorMessage = getErrorMessage(error, 'Unexpected error occured. ');
        throw new Error(errorMessage);
      }
    },

    async verifyUser({ commit }, { email, code, redirectURL }) {
      const { data } = (await request.post('/api/verify').send({ email, code, redirectURL })).body;
      jwtUtils.store(data.jwt);
      commit('setLoggedInUser', data.me);
      window.location.href = data.redirect_url;
    },

    /**
     * Activate an integration for this user.
     *
     * @param {{ commit: function }} commit - Function to commit changes via mutators.
     * @param {object} data
     * @param {string} data.integration - The integration to deactivate.
     * @param {object} [data.payload] - The payload to send in the request.
     */
    async activateIntegration({ commit }, { integration, payload = {} }) {
      const { data } = (await request.post(`/api/integrations/activate/${integration}`).send(payload)).body;
      commit('setLoggedInUser', data.user);
    },

    /**
     * Deactivate an integration for this user.
     *
     * @param {{ commit: function }} commit - Function to commit changes via mutators.
     * @param {{ integration: string }} integration - The integration to deactivate.
     */
    async deactivateIntegration({ commit }, { integration }) {
      const { data } = (await request.post(`/api/integrations/deauthorize/${integration}`)).body;
      commit('setLoggedInUser', data.user);
    },

    /**
     * Generate a new api key for this user.
     */
    async generateApiKey({ commit }, payload) {
      const response = await axios.post('/api/apiKey', payload);
      const data = response.data.data;

      commit('setApiContext', data);

      return data.api_key;
    },

    /**
     * Remove this users api key.
     */
    async revokeApiKey({ commit }) {
      await axios.delete('/api/apiKey');

      commit('setApiContext', { description: null, created_at: null });
    },
  },
};
